<template>
  <div>
    <div
      v-b-toggle.collapse-edit-terms
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span> Terms </span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse-edit-terms"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column">
        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group class="m-1">
              <template #label>
                <span
                  class="font-work-sans"
                  @click="toggleVat"
                >
                  Vat Percentage - Retailer
                </span>
                <span class="ms-1">
                  <b-icon
                    class="cursor-pointer"
                    :icon="editingOrder.isVatEligible ? 'toggle-on' : 'toggle-off'"
                    :variant="editingOrder.isVatEligible ? 'primary' : ''"
                    scale="1.5"
                    @click="toggleVat"
                  />
                </span>
                <span
                  id="tooltip_vat"
                  class="m-2"
                >
                  <b-icon
                    icon="exclamation-circle"
                    variant="primary"
                    scale="1"
                  />
                </span>
              </template>
              <k-form-input
                v-model="editingOrder.vatPercentage"
                type="text"
                name="vat-percentage"
                placeholder="0 - 50"
                :disabled="!editingOrder.isVatEligible"
              />
            </k-form-group>
            <b-tooltip
              class="font-Inter text-center"
              target="tooltip_vat"
              placement="top"
              triggers="hover"
            >
              Should be in percentage: 0 - 50%
            </b-tooltip>
          </div>
          <div class="w-50">
            <k-form-group class="m-1">
              <template #label>
                <span
                  class="font-work-sans"
                  @click="toggleBrandVat"
                >
                  Vat Percentage - Brand
                </span>
                <span class="ms-1">
                  <b-icon
                    class="cursor-pointer"
                    :icon="editingOrder.isVatEligibleForBrands ? 'toggle-on' : 'toggle-off'"
                    :variant="editingOrder.isVatEligibleForBrands ? 'primary' : ''"
                    scale="1.5"
                    @click="toggleBrandVat"
                  />
                </span>
                <span
                  id="tooltip_vat"
                  class="m-2"
                >
                  <b-icon
                    icon="exclamation-circle"
                    variant="primary"
                    scale="1"
                  />
                </span>
              </template>
              <k-form-input
                v-model="editingOrder.vatPercentageForBrands"
                type="text"
                name="vat-percentage"
                placeholder="0 - 50"
                :disabled="!editingOrder.isVatEligibleForBrands"
              />
            </k-form-group>
            <b-tooltip
              class="font-Inter text-center"
              target="tooltip_vat"
              placement="top"
              triggers="hover"
            >
              Should be in percentage: 0 - 50%
            </b-tooltip>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Brand’s Payment terms"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="editingOrder.paymentTermsForBrand.length ? 'has-value' : ''"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="editingOrder.paymentTermsForBrand || 'Select a payment terms'"
                :disabled="isDisableBrandPaymentTerms"
                menu-class="payment-terms-dropdown-menu"
              >
                <b-dropdown-item
                  v-for="option in Object.values(PAYMENT_TERMS).map((_) => _.TEXT)"
                  :key="option"
                  :active="editingOrder.paymentTermsForBrand === option"
                  @click="editingOrder.paymentTermsForBrand = option"
                >
                  <span>
                    {{ option }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Brand’s Commercial terms"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="editingOrder.commercialTerms"
                type="text"
                name="brand-commercial-terms"
                placeholder="Brand’s Commercial terms"
              />
            </k-form-group>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Retailer’s Payment terms"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 mt-1 k-search-dropdown dropdown-custom-class"
                :class="editingOrder.paymentTerms.length ? 'has-value' : ''"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="editingOrder.paymentTerms || 'Select a payment terms'"
              >
                <b-dropdown-item
                  v-for="option in Object.values(PAYMENT_TERMS).map((_) => _.TEXT)"
                  :key="option"
                  :active="editingOrder.paymentTerms === option"
                  @click="editingOrder.paymentTerms = option"
                >
                  <span>
                    {{ option }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Retailer’s Logistics terms"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 mt-1 k-search-dropdown dropdown-custom-class"
                :class="editingOrder.logisticsTerms.length ? 'has-value' : ''"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="editingOrder.logisticsTerms || 'Select a logistics terms'"
              >
                <b-dropdown-item
                  v-for="option in Object.values(LOGISTICS_TERMS)"
                  :key="option"
                  :active="editingOrder.logisticsTerms === option"
                  @click="editingOrder.logisticsTerms = option"
                >
                  <span>
                    {{ option }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Price Multiplier"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="editingOrder.discount"
                type="text"
                name="price-multiplier"
                placeholder="Price Multiplier"
              />
            </k-form-group>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BIcon,
  BTooltip,
} from 'bootstrap-vue'
import {
  ORDER_STATUSES,
  PAYMENT_TERMS,
  LOGISTICS_TERMS,
} from '@/constants'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { mapState } from 'vuex'
import { SET_EDITING_ORDER_PROPERTY } from '@/store/modules/order.module'
import { KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'

const { ORDER_STATUS } = constants

export default {
  name: 'EditTerms',
  components: {
    BCollapse,
    BDropdown,
    BDropdownItem,
    BIcon,
    EditSummaryCollapseIcon,
    BTooltip,
    KFormGroup,
    KFormInput,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  data() {
    return {
      isVisible: true,
      ORDER_STATUSES,
      ORDER_STATUS,
      PAYMENT_TERMS,
      LOGISTICS_TERMS,
    }
  },
  computed: {
    ...mapState({
      editingOrder: state => state.orders.editingOrder,
    }),
    isDisableBrandPaymentTerms() {
      const status = [ORDER_STATUS.CONFIRMED, ORDER_STATUS.REJECTED]
      return status.includes(this.editingOrder.status)
    },
  },
  methods: {
    toggleVat() {
      this.$store.commit(SET_EDITING_ORDER_PROPERTY, { property: 'isVatEligible', value: !this.editingOrder.isVatEligible })
    },
    toggleBrandVat() {
      this.$store.commit(SET_EDITING_ORDER_PROPERTY, { property: 'isVatEligibleForBrands', value: !this.editingOrder.isVatEligibleForBrands })
    },
  },
}
</script>

<style lang="scss">
.payment-terms-dropdown-menu {
  z-index: 2000;
}
</style>
