<template>
  <div class="w-100">
    <div
      v-if="loading"
      class="spinner-block"
    >
      <b-spinner />
    </div>
    <div
      v-else
      class="row d-flex justify-content-between"
    >
      <div class="col-lg-6 col-xs-12 pe-lg-2 d-flex flex-column">
        <div class="w-100 card-container">
          <k-card class="w-100 k-card-custom-class">
            <edit-summary :is-disable-option="isDisableOption" />
          </k-card>
        </div>
        <div class="w-100 card-container">
          <k-card class="w-100 k-card-custom-class">
            <edit-addresses />
          </k-card>
        </div>
      </div>
      <div class="col-lg-6 col-xs-12 ps-lg-2 d-flex flex-column">
        <div class="w-100 card-container">
          <k-card class="w-100 k-card-custom-class">
            <edit-terms />
          </k-card>
        </div>
      </div>
    </div>

    <b-modal
      id="open_confirmation"
      size="lg"
      modal-class="modal-primary"
      centered
      hide-header-close
      hide-header
      hide-footer
    >
      <div>
        <div
          class="
            pb-1
            border-bottom
            d-flex
            justify-content-between
            font-montserrat
          "
        >
          <span class="custom-modal-heading">Transaction Warning </span>
          <feather-icon
            class="close-x-icon"
            icon="XIcon"
            size="24"
            @click="onClickCancel()"
          />
        </div>

        <div class="mt-2">
          <p class="modal-message font-montserrat text-center">
            {{ confirmMessage }}
          </p>
        </div>
        <div class="mt-3 text-center">
          <k-button
            variant="outline-info"
            class="me-2"
            @click="onClickCancel()"
          >
            Cancel
          </k-button>
          <k-button
            variant="info"
            @click="updateOrder(proceedString)"
          >
            Continue
          </k-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { apiToastError, apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { FETCH_ORDER_BY_ID } from '@/store/modules/order.module'
import { KButton, KCard } from '@kingpin-global/kingpin-ui'
import { BModal, VBModal } from 'bootstrap-vue'
import { mapState } from 'vuex'
import EditAddresses from './EditAddresses.vue'
import EditSummary from './EditSummary.vue'
import EditTerms from './EditTerms.vue'
import { isOptionAccessible } from './order-management-utils'

export default {
  name: 'OrderDetailsTab',
  components: {
    KCard,
    EditSummary,
    EditTerms,
    EditAddresses,
    BModal,
    FeatherIcon,
    KButton,
  },
  directives: {
    'v-b-modal': VBModal,
  },
  data() {
    return {
      orderId: this.$route.params.orderId,
      orderData: {},
      orderStatus: '',
      payload: {},
      isDisableOption: false,
      loading: false,
      confirmMessage: '',
      proceedString: '',
    }
  },
  emits: ['on-updated'],
  computed: {
    ...mapState({
      editingOrder: state => state.orders.editingOrder,
    }),
  },
  watch: {
    editingOrder: {
      handler(val) {
        this.setPayload(val)
      },
      deep: true,
    },
  },
  created() {
    this.$root.$refs.OrderDetailsTab = this
    this.loadOrderDetails()
  },
  methods: {
    loadOrderDetails() {
      this.loading = true
      this.$store
        .dispatch(FETCH_ORDER_BY_ID, this.orderId)
        .then(res => {
          this.orderData = res
          this.orderStatus = this.orderData.status
          this.isDisableOption = isOptionAccessible(this.orderStatus)
          this.loading = false
        })
        .catch(err => {
          apiToastError(err)
          this.loading = false
        })
    },
    setPayload(orderData) {
      this.payload = {
        commercialTerms: parseFloat(orderData.commercialTerms) || 0,
        paymentTerms: orderData.paymentTerms || '',
        logisticsTerms: orderData.logisticsTerms || '',
        paymentTermsForBrand: orderData.paymentTermsForBrand || '',
        discount: parseFloat(orderData.discount) || 0,
        isVatEligible: orderData.isVatEligible || false,
        isVatEligibleForBrands: orderData.isVatEligibleForBrands || false,
        shippingAddressForRetailer: orderData.shippingAddressForRetailer,
        shippingAddressForBrand: orderData.shippingAddressForBrand,
        billingAddressForBrand: orderData.billingAddressForBrand,
        billingAddressForRetailer: orderData.billingAddressForRetailer,
        isSelfManagedOrder: orderData.isSelfManagedOrder || false,
      }
      if (this.orderStatus !== orderData.status) {
        this.payload.status = orderData.status
      }
      if (orderData.isVatEligible && orderData.vatPercentage) {
        this.payload.vatPercentage = parseFloat(orderData.vatPercentage)
      }
      if (orderData.isVatEligibleForBrands && orderData.vatPercentageForBrands) {
        this.payload.vatPercentageForBrands = parseFloat(orderData.vatPercentageForBrands)
      }
      if (orderData.deliveryDate) {
        this.payload.deliveryDate = orderData.deliveryDate ? new Date(orderData.deliveryDate) : 0
      }
    },
    updateOrder(shoulProceed = '') {
      const params = {}
      if (shoulProceed) {
        params[shoulProceed] = true
      }
      const payload = this.payload
      this.$kpRequest({
        ...this.$kpParams(this.$kpEndpoint.orders.patch, this.orderId),
        payload,
        params,
      })
        .then(res => {
          if (res.data.data.shouldUpdateTransaction || res.data.data.shouldCreateTransaction) {
            this.proceedString = res.data.data.shouldUpdateTransaction ? 'shouldUpdateTransaction' : 'shouldCreateTransaction'
            this.confirmMessage = res.data.message
            this.$bvModal.show('open_confirmation')
            this.$emit('on-updated')
          } else {
            apiToastSuccessV2('Changes have been saved!')
            this.$emit('on-updated')
            this.loadOrderDetails()
            this.onClickCancel()
          }
        })
        .catch(err => {
          apiToastErrorV2(err)
          this.$emit('on-updated')
        })
    },
    onClickCancel() {
      this.$bvModal.hide('open_confirmation')
      this.$emit('on-updated')
    },
  },
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/pages/kp-details-tab.scss";
</style>
