<template>
  <div>
    <div
      v-b-toggle.collapse-edit-summary
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span> Order Summary </span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse-edit-summary"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column">
        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Order Status"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="editingOrder.status.length ? 'has-value' : ''"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="ORDER_STATUSES[editingOrder.status] || 'Select a status'"
                :disabled="isDisableOption"
              >
                <b-dropdown-item
                  v-for="option in Object.keys(orderStatusOptions)"
                  :key="option"
                  :active="editingOrder.status === option"
                  @click="onSelectStatus(option)"
                >
                  <span>
                    {{ orderStatusOptions[option] }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Amount"
              label-class="font-work-sans"
            >
              <k-form-input
                type="text"
                name="amount"
                :value="editingOrder.totalPriceAfterDiscount || editingOrder.totalPrice"
                placeholder="Amount"
                disabled
              />
            </k-form-group>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Brand Name"
              label-class="font-work-sans"
            >
              <k-form-input
                type="text"
                name="brand-name"
                :value="editingOrder.brandName"
                placeholder="Brand Name"
                disabled
              />
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Retailer Name"
              label-class="font-work-sans"
            >
              <k-form-input
                type="text"
                name="retailer-name"
                :value="editingOrder.retailerName"
                placeholder="Retailer Name"
                disabled
              />
            </k-form-group>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-around">
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Collection Name"
              label-class="font-work-sans"
            >
              <k-form-input
                type="text"
                name="collection-name"
                :value="editingOrder.collectionName"
                placeholder="Collection Name"
                disabled
              />
            </k-form-group>
          </div>
          <div class="w-50">
            <k-form-group
              class="m-1"
              label-content="Delivery Date"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="deliveryDate"
                type="date"
                name="delivery-date"
                placeholder="Delivery Date"
              />
            </k-form-group>
          </div>
        </div>

        <div class="pt-2 ps-1 d-flex flex-row align-items-center">
          <span>
            <label
              class="custom-checkbox-container"
            >
              <input
                v-model="editingOrder.isSelfManagedOrder"
                type="checkbox"
              >
              <span class="checkmark" />
            </label>
          </span>
          <span
            class="ps-4 cursor-pointer"
            @click="editingOrder.isSelfManagedOrder=!editingOrder.isSelfManagedOrder"
          >Is Self Managed</span>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { ORDER_STATUSES, ORDER_STATUS } from '@/constants'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { SET_EDITING_ORDER_PROPERTY } from '@/store/modules/order.module'
import { mapState } from 'vuex'
import { KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import { formatDateForInput } from '@/@core/utils/format'

const { formattedDate } = utils

export default {
  name: 'EditSummary',
  components: {
    BCollapse,
    BDropdown,
    BDropdownItem,
    EditSummaryCollapseIcon,
    KFormGroup,
    KFormInput,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    isDisableOption: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      isVisible: true,
      ORDER_STATUSES,
      orderStatusOptions: {
        [ORDER_STATUS.ACCEPTED]: ORDER_STATUSES[ORDER_STATUS.ACCEPTED],
        [ORDER_STATUS.CANCELLED]: ORDER_STATUSES[ORDER_STATUS.CANCELLED],
      },
      deliveryDate: null,
    }
  },
  computed: {
    ...mapState({
      editingOrder: state => state.orders.editingOrder,
    }),
  },
  watch: {
    deliveryDate() {
      this.editingOrder.deliveryDate = this.deliveryDate
    },
  },
  created() {
    this.deliveryDate = this.editingOrder.deliveryDate ? formatDateForInput(formattedDate(this.editingOrder.deliveryDate)) : ''
    this.$store.commit(SET_EDITING_ORDER_PROPERTY, { property: 'isSelfManagedOrder', value: !!this.editingOrder.isSelfManagedOrder })
  },
  methods: {
    onSelectStatus(option) {
      this.$store.commit(SET_EDITING_ORDER_PROPERTY, { property: 'status', value: option })
    },
  },
}
</script>
